import React from 'react'

const Footer = () => {
  return (
        <footer>
            <div className="footer-intro">
                <div className="footer-head">
    
                    <div className="text">
                        <p className="p">
                            These games are brought to you by GAMESTACK , an open source gaming platform.
                        </p>
                        
                    </div>
                    </div>
                    <p className="p2"><br/>
                        By using this service, you agree to the Google Terms of Service. <a href="#">Google's Privacy Policy</a> sets out how we handle your data. <br/><br/>

GAMESTACK uses <a href="#">cookies</a> from Google to deliver and enhance the quality of its services, to analyze traffic, and to personalize the content and ads that you see. GAMESTACK uses <a href="#">Google AdSense</a> to serve the ads that you see. You can opt out of ads personalization with ad settings. Note that this setting also affects ads personalization on other sites and apps that partner with Google. <a href="https://github.com/gamestackcomng/games">vist source codes</a>
                    </p>
            </div>

        </footer>
  )
}

export default Footer