import React, { useState } from 'react'
import Footer from "./footer";
import Home from "./home";  
import Header from "./header";  

const Main = () => {
  
  return (
    <>
        <Header/>
        <Home />
        <Footer/>
    </>
  )
}

export default Main