import { useState } from "react";
import {Routes, Route,useNavigate } from 'react-router-dom';
import Main from "./main";
import Layout from "./layout";
import GamePage from "./gamepage";
import company_logo from "./images/company-logo.svg"
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC40o4FwLYI8oQgcmQ-Ud9-LW-lMJGV4jM",
  authDomain: "gamestack-2a001.firebaseapp.com",
  projectId: "gamestack-2a001",
  storageBucket: "gamestack-2a001.appspot.com",
  messagingSenderId: "160767761189",
  appId: "1:160767761189:web:5e5ee92a492479528918cc",
  measurementId: "G-TVZQCYVD0D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
function App() {
  const [gamesLink,setGamesLink]=useState("")
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route index element={<Main/>}/>
          <Route path='games/:gameTitle'>
            <Route index element={ <GamePage gamesLink={gamesLink}/>}/>
          </Route> 
          <Route path="*" element={<Layout/>} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
