import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const root = ReactDOM.createRoot(document.querySelector(".container"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path='/*' element={<App/>}/>
      </Routes> 
    </Router>
  </React.StrictMode>
);
