import React, { useState } from 'react'
import home_icon from "./images/logoShit.svg"
const Header = () => {
  const installAppBtn = document.getElementsByClassName('btn_download_handler');
  const [status ,setStatus]=useState(false)
  // installAppBtn.addEventListener('click',)

  async function runDownload  () {
    try {
      const result = await window.deferredPrompt.prompt();
      if (result.outcome === 'accepted') {
        
      } else {
        alert("seems like this feature is not supported on your browser , try using chrome or it seems like you have already downloaded this app")
      }
    } catch (error) {
      alert("seems like this feature is not supported on your browser , try using chrome or it seems like you have already downloaded this app")
    }
  };
  window.addEventListener('beforeinstallprompt', event => {
    event.preventDefault();
    window.deferredPrompt = event;
  });

  return (
    <header>
        <div className="logo"><img src={home_icon} alt="logo"/></div>
        <div className="more-options">
            <button className="btn-dark btn_download_handler" onClick={runDownload}>DOWNLOAD</button>
        </div>
    </header>
  )
}

export default Header