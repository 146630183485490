import React, { useState } from 'react'
import  { useNavigate ,Link} from 'react-router-dom'
export function hdhdh(data){
    console.log(data)
    return data
}
const Games = () => {
    const [games,setGames] =useState("")
    const navigator=useNavigate()
    
    const browseRoute=(myRoute)=>{
        navigator(`games/${myRoute}`)
        setGames(myRoute)
        hdhdh(myRoute)
    }
    
        // function enterFullScreen(element) {
        //     if(element.requestFullscreen) {
        //     element.requestFullscreen();
        //     }else if (element.mozRequestFullScreen) {
        //     element.mozRequestFullScreen();     // Firefox
        //     }else if (element.webkitRequestFullscreen) {
        //     element.webkitRequestFullscreen();  // Safari
        //     }else if(element.msRequestFullscreen) {
        //     element.msRequestFullscreen();      // IE/Edge
        //     }
        // };
        
       
        
        // document.addEventListener('fullscreenchange', (event) => {
        //     if (document.fullscreenElement) {
        //     console.log('Entered fullscreen:', document.fullscreenElement);
        //     } else {
        //     console.log('Exited fullscreen.');
        //     }
        // });
    const game_details=[
        {
            game_title:"SLIDING PUZZLE",
            game_detail:"Slide boxes into their correct order",
            game_route:"Sliding_Puzzle_game"
            
        },
        {
            game_title:"SNAKE GAME",
            game_detail:"Avoid walls swipe left / right / up / down to change direction",
            game_route:"Snake_game"
        },
        {
            game_title:"SOUND MEMORY",
            game_detail:"See how long you can complete the sequence to button pressed",
            game_route:"Simon_game"
        },
        {
            game_title:"NUMBER SLIDE",
            game_detail:"Slide tiles with same number until you reach 2048",
            game_route:"number_slide"
        },
        // {
        //     game_title:"WATER SORT GAME",
        //     game_detail:"Pour water between tubes until its filled with one color",
        //     game_route:"Water_sort_game"
        // },
        {
            game_title:"HANGMAN GAME",
            game_detail:"Guess all the letters to make up to world before you hang",
            game_route:"hangman_game"
        },
        {
            game_title:"CARD MEMORY",
            game_detail:"FLip two cards and find pairs before you exceed your flips",
            game_route:"memory_cards_game"
        },
        {
            game_title:"TOWER STACK",
            game_detail:"stack towers on each other carefully",
            game_route:"tower_game-master"
        },
        // {
        //     game_title:"WORD GUESSING",
        //     game_detail:"Lorem ipsum dolor sit appet kdkd dd",
        //     game_route:"Word_guessing_game"
        // },
        {
            game_title:"BOUNCING BALL",
            game_detail:"click each walls change color to the ball's color before bounce",
             game_route:"coloron_game"
        },
        // {
        //     game_title:"PACMAN GAME",
        //     game_detail:"Lorem ipsum dolor sit appet kdkd dd",
        //      game_route:"pacman_game"
        // },
        {
            game_title:"SUDOKU GAME",
            game_detail:"Avoid same number in rows and column and avoid repitition ",
            game_route:"sudoku_game"
        },
        {
            game_title:"CHICKEN HOP",
            game_detail:"Hop chicken to cross the road and watch carefully to avoid death",
            game_route:"chicken_hop"
        }
        // {
        //     game_title:"SIMPLE MATHS",
        //     game_detail:"Lorem ipsum dolor sit appet kdkd dd",
        //     game_route:"flappy_bird"
        // }
    ]
   const render_games=game_details.map(item=>(
    <li key={item.game_title} >
        <Link to={`/games/${item.game_route}`}>
        <div className={`game-holder image-${item.game_route}`}>
            <div className={`image-holder ${item.game_route}`}></div>
            <div className="play-details">
                <div className="details-holder">
                    <div className="game-detail">
                        <span className='games-description'>{item.game_detail}</span>
                        <h2>{item.game_title}</h2>
                    </div>
                    <div className="btn">
                        
                    </div>
                </div>
            </div>
        </div>
        </Link>
    </li>
   ))
    return (
        <>
        {render_games}
        </>
    )
}

export default Games