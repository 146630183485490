import React, { useState } from 'react'
import Link, { useNavigate, useParams } from 'react-router-dom'
// import { hdhdh } from './games'

const GamePage = ({gamesLink}) => {
    // const vava=hdhdh()
   
    const navigator=useNavigate()
    const gamesLinkHere =useParams()
    function endNavigate(){
        navigator("/")
    }
  return (
    <div className='gamePage'>
        <div className="score-board">
            <div className="board-details">
                <button onClick={()=>endNavigate()} className='show_btn_sms'><svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#ffffff" d="M512 128 128 447.936V896h255.936V640H640v256h255.936V447.936z"></path></g></svg></button>
            </div>
        </div>
        <iframe allow='autoplay' className='iframe ' title={gamesLinkHere.gameTitle} src={`https://gamestackcomng.github.io/games/${gamesLinkHere.gameTitle}`}>

        </iframe>
    </div>
  )
}

export default GamePage